import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-medium text-gray-900" }
const _hoisted_2 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_3 = { class: "font-medium text-gray-900" }
const _hoisted_4 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_5 = { class: "font-medium text-gray-900" }
const _hoisted_6 = { class: "text-sm text-gray-500" }
const _hoisted_7 = { class: "flex text-gray-900" }
const _hoisted_8 = { class: "flex text-gray-900" }
const _hoisted_9 = { class: "flex text-gray-900" }
const _hoisted_10 = { class: "flex flex-row items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MkBadge = _resolveComponent("MkBadge")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_SpecialityButton = _resolveComponent("SpecialityButton")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_PopoverLink = _resolveComponent("PopoverLink")!
  const _component_BasePopover = _resolveComponent("BasePopover")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_BasePagination = _resolveComponent("BasePagination")!
  const _directive_allow = _resolveDirective("allow")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DataTable, {
      loading: _ctx.loadingConsults,
      columns: _ctx.tableColumns,
      rows: _ctx.rows,
      sort: _ctx.sort,
      class: "w-full mt-6",
      onSort: _ctx.updateSort
    }, {
      "cell.created.time": _withCtx(({ row }) => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.formatDate(row.created.time)), 1),
        _createElementVNode("div", _hoisted_2, _toDisplayString(row.created.user), 1)
      ]),
      "cell.clinic_configuration_name": _withCtx(({ row }) => [
        _createElementVNode("div", _hoisted_3, _toDisplayString(row.clinic_configuration_name), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(row.scheduled_location.title), 1)
      ]),
      "cell.scheduled_location": _withCtx(({ value }) => [
        _createElementVNode("div", _hoisted_5, _toDisplayString(value.title), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(value.location), 1)
      ]),
      "cell.consult_type": _withCtx(({ value }) => [
        (value?.name === 'New Patient')
          ? (_openBlock(), _createBlock(_component_MkBadge, {
              key: 0,
              class: "w-full",
              label: _ctx.$t('custom.uhb.worklist.new-patient'),
              color: "primary",
              stroked: ""
            }, null, 8, ["label"]))
          : _createCommentVNode("", true),
        (value?.name === 'Follow-Up Patient')
          ? (_openBlock(), _createBlock(_component_MkBadge, {
              key: 1,
              class: "w-full",
              label: _ctx.$t('custom.uhb.worklist.follow-up'),
              color: "success",
              stroked: ""
            }, null, 8, ["label"]))
          : _createCommentVNode("", true)
      ]),
      "cell.status": _withCtx(({ value }) => [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", {
            class: _normalizeClass(["mr-2", _ctx.statusColor(value)])
          }, " • ", 2),
          _createTextVNode(" " + _toDisplayString(value === "Reviewing"
              ? _ctx.$t("custom.uhb.consult.awaiting-review")
              : value), 1)
        ])
      ]),
      "cell.organisation_unit_owner_title": _withCtx(({ value }) => [
        _createElementVNode("div", _hoisted_8, _toDisplayString(value), 1)
      ]),
      "cell.location": _withCtx(({ value }) => [
        _createElementVNode("div", _hoisted_9, _toDisplayString(value), 1)
      ]),
      "cell.action": _withCtx(({ value, row }) => [
        _createElementVNode("div", _hoisted_10, [
          (row.review_overdue)
            ? (_openBlock(), _createBlock(_component_MkBadge, {
                key: 0,
                color: "danger",
                class: "mr-4"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.review.overdue")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (
              row.review &&
                !row.review.completed_at &&
                row.review.lockedBy &&
                row.review.lockedBy.id !== _ctx.currentUserId
            )
            ? (_openBlock(), _createBlock(_component_MkBadge, {
                key: 1,
                color: "gray",
                class: "btn rounded-full flex-col justify-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BaseIcon, {
                    name: "lock-2",
                    position: "left",
                    style: {"width":"16px","height":"16px"}
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("custom.uhb.review.locked-by", {
                user: row.review.locked_by_user_full_name,
              })), 1)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (
              row.review?.lockedBy?.id === _ctx.currentUserId &&
                _ctx.showViewLabel(value.status)
            )
            ? (_openBlock(), _createBlock(_component_BaseButton, {
                key: 2,
                color: "ghost",
                class: "rounded-md",
                disabled: _ctx.viewButtonIsDisabled(value),
                onClick: ($event: any) => (_ctx.startView(value))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.showViewLabel(value.status)) + " ", 1),
                  _createVNode(_component_BaseIcon, {
                    name: "chevron-right",
                    class: "ml-2"
                  })
                ]),
                _: 2
              }, 1032, ["disabled", "onClick"]))
            : _createCommentVNode("", true),
          _createVNode(_component_BasePopover, { position: "left" }, {
            button: _withCtx(() => [
              _createVNode(_component_SpecialityButton, {
                class: "ml-3",
                disabled: _ctx.loadingAction
              }, null, 8, ["disabled"])
            ]),
            menu: _withCtx(() => [
              (
                  row.review &&
                    !row.review.completed_at &&
                    row.review.lockedBy &&
                    row.review.lockedBy.id !== _ctx.currentUserId
                )
                ? _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                    key: 0,
                    disabled: _ctx.loadingAction,
                    onClick: ($event: any) => (
                  _ctx.$emit('clickUnlockConsult',
                        row.review.patient_id,
                        row.review.id,
                        row.review.locked_by_user_full_name
                  )
                )
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.review.unlock")), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick"])), [
                    [_directive_allow, 'review:update']
                  ])
                : _createCommentVNode("", true),
              (value.status === 'Needs Scheduling')
                ? _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                    key: 1,
                    disabled: _ctx.loadingAction,
                    onClick: ($event: any) => (_ctx.$emit('showScheduleConsultModal',value.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.consult.schedule-patient")), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick"])), [
                    [_directive_allow, 'consult:schedule']
                  ])
                : _createCommentVNode("", true),
              (value.status === 'Awaiting Consult')
                ? _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                    key: 2,
                    disabled: _ctx.loadingAction,
                    onClick: ($event: any) => (_ctx.$emit('startConsult',value))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.consult.start")), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick"])), [
                    [_directive_allow, 'consult:update']
                  ])
                : _createCommentVNode("", true),
              (value.status === 'Consulting')
                ? _withDirectives((_openBlock(), _createBlock(_component_PopoverLink, {
                    key: 3,
                    disabled: _ctx.loadingAction,
                    to: `/patients/${_ctx.patient.id}/forms/${row.form_submission_id}`
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.consult.resume")), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "to"])), [
                    [_directive_allow, 'consult:update']
                  ])
                : _createCommentVNode("", true),
              (value.status === 'Awaiting Review')
                ? _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                    key: 4,
                    disabled: _ctx.loadingAction,
                    onClick: ($event: any) => (_ctx.$emit('startReview',value))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.review.start")), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick"])), [
                    [_directive_allow, 'review:create']
                  ])
                : _createCommentVNode("", true),
              (
                  value.status === 'Complete' ||
                    value.status === 'Sent to Emergency'
                )
                ? _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                    key: 5,
                    disabled: _ctx.loadingAction,
                    onClick: ($event: any) => (_ctx.$emit('viewReviewSummary', value))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.review.summary.view")), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick"])), [
                    [_directive_allow, 'review_summary:read']
                  ])
                : _createCommentVNode("", true),
              (
                  value.status === 'Complete' ||
                    value.status === 'Sent to Emergency'
                )
                ? _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                    key: 6,
                    disabled: _ctx.loadingAction,
                    onClick: ($event: any) => (_ctx.$emit('viewPatientLetter', value))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.correspondence.view-patient-letter")), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick"])), [
                    [_directive_allow, 'patient_letter:read']
                  ])
                : _createCommentVNode("", true),
              (value.fileAttachmentId)
                ? _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                    key: 7,
                    disabled: _ctx.loadingAction,
                    onClick: ($event: any) => (_ctx.$emit('viewSupportingDocument', value))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.review.view-supporting-document")), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick"])), [
                    [_directive_allow, 'consult:read']
                  ])
                : _createCommentVNode("", true),
              (
                  [
                    'Needs Scheduling',
                    'Awaiting Consult',
                    'Consulting',
                  ].includes(row.status)
                )
                ? _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                    key: 8,
                    onClick: ($event: any) => (_ctx.$emit('openRejectedReasonModal', row.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.consult.reject-for-distributed-clinic")), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])), [
                    [_directive_allow, 'consult:reject']
                  ])
                : _createCommentVNode("", true),
              (row.status === 'Complete')
                ? _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                    key: 9,
                    onClick: ($event: any) => (
                  _ctx.$emit('resendPatientLetter', row.review.patient_id, row.review.id)
                )
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.correspondence.resend-patient-letter")), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])), [
                    [_directive_allow, 'patient_letter:resend']
                  ])
                : _createCommentVNode("", true),
              (row.status === 'Rejected')
                ? _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                    key: 10,
                    onClick: ($event: any) => (_ctx.$emit('openResolveReasonModal', row.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.consult.rejected-resolve-button")), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])), [
                    [_directive_allow, 'consult:resolve']
                  ])
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024)
        ])
      ]),
      _: 1
    }, 8, ["loading", "columns", "rows", "sort", "onSort"]),
    (_ctx.total && _ctx.total > _ctx.perPage)
      ? (_openBlock(), _createBlock(_component_BasePagination, {
          key: 0,
          "model-value": _ctx.page,
          "items-name": _ctx.$t('custom.uhb.consult.consults').toLowerCase(),
          "page-size": _ctx.perPage,
          length: _ctx.total,
          "onUpdate:modelValue": _ctx.changePage
        }, null, 8, ["model-value", "items-name", "page-size", "length", "onUpdate:modelValue"]))
      : _createCommentVNode("", true)
  ]))
}